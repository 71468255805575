import Vue from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

Vue.component("font-awesome-icon", FontAwesomeIcon);

import {
  faPlus,
  faEdit,
  faTrash,
  faPencilAlt,
  faSearch,
  faTimes,
  faPlusCircle,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faPlus,
  faEdit,
  faTrash,
  faPencilAlt,
  faSearch,
  faTimes,
  faPlusCircle,
  faDownload
);
