import Vue from 'vue'
import Vuex from 'vuex'
import Router from '../router/index'
import axios from 'axios'
Vue.use(Vuex)

const domain = 'https://api-audit-beta.batigo.fr/api'

export default new Vuex.Store({
  state: {
    token: window.localStorage.getItem('access_token'),
    user: window.localStorage.getItem('user')
      ? JSON.parse(window.localStorage.getItem('user'))
      : null,
    userloading: false,
    users: [],
    pdf: [],
    pdfloading: false,
  },
  getters: {
    getOnlineUser: (state) => state.user,
    getUserLoading: (state) => state.userloading,
    getAllUsers: (state) => state.users,
    getPdfLoading: (state) => state.pdfloading,
    getAllPdf: (state) => state.pdf,
  },
  mutations: {
    DO_NOTHING() {},
    SET_TOKEN(state, payload) {
      if (payload) {
        window.localStorage.setItem('access_token', payload)
        state.token = payload
      } else {
        state.token = null
      }
    },

    SET_USER(state, payload) {
      if (payload) {
        state.user = payload
      } else {
        state.user = null
      }
    },

    SET_USERS(state, payload) {
      if (payload) {
        state.users = payload
      } else {
        state.users = []
      }
    },

    SET_PDF(state, payload) {
      if (payload) {
        state.pdf = payload
      } else {
        state.pdf = []
      }
    },

    SET_LOADINGUSER(state, payload) {
      if (payload) {
        state.userloading = payload
      } else {
        state.userloading = false
      }
    },

    SET_LOADINGPDF(state, payload) {
      if (payload) {
        state.pdfloading = payload
      } else {
        state.pdfloading = false
      }
    },

    UPDATE_USERS(state, payload) {
      for (let index = 0; index < state.users.length; index++) {
        if (state.users[index].id == payload.id) {
          state.users[index] = payload
          break
        }
      }
    },

    UPDATE_PDF(state, payload) {
      for (let index = 0; index < state.pdf.length; index++) {
        if (state.pdf[index].id == payload.id) {
          state.pdf[index] = payload
          break
        }
      }
    },

    PUSH_USER(state, payload) {
      if (payload) {
        state.users.push(payload)
      }
    },

    PUSH_PDF(state, payload) {
      if (payload) {
        state.pdf.push(payload)
      }
    },

    DELETE_USER(state, payload) {
      for (let index = 0; index < state.users.length; index++) {
        if (state.users[index].id == payload.id) {
          state.users.splice(index, 1)
          break
        }
      }
    },

    DELETE_PDF(state, payload) {
      for (let index = 0; index < state.pdf.length; index++) {
        if (state.pdf[index].id == payload.id) {
          state.pdf.splice(index, 1)
          break
        }
      }
    },
  },
  actions: {
    async login({ commit }, payload) {
      commit('SET_LOADINGUSER', true)
      try {
        const responce = await axios.post(domain + `/login`, {
          email: payload.email,
          password: payload.password,
          remember_me: true,
        })
        commit('SET_USER', responce.data.role)
        commit('SET_TOKEN', responce.data.access_token)
        commit('SET_LOADINGUSER', false)
        Router.replace('/audit')
        return true
      } catch (error) {
        commit('SET_LOADINGUSER', false)
        return false
      }
    },

    async logout({ commit }) {
      commit('DO_NOTHING')
      try {
        commit('SET_TOKEN', null)
        commit('SET_USER', null)
        localStorage.removeItem('access_token')
        Router.replace('/')
        return true
      } catch (error) {
        Router.replace('/')
        return false
      }
    },

    async all_users({ commit }) {
      commit('SET_LOADINGUSER', true)
      try {
        const responce = await axios.get(domain + `/user`, {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        })

        commit('SET_USERS', responce.data)
        commit('SET_LOADINGUSER', false)
        return true
      } catch (error) {
        commit('SET_LOADINGUSER', false)
        return false
      }
    },

    async store_user({ commit }, payload) {
      commit('SET_LOADINGUSER', true)
      try {
        await axios.post(domain + `/signup`, payload, {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        })
        // commit("PUSH_USER", payload);
        commit('SET_LOADINGUSER', false)
        return true
      } catch (error) {
        commit('SET_LOADINGUSER', false)
        return true
      }
    },

    async edit_user({ commit }, payload) {
      commit('SET_LOADINGUSER', true)
      try {
        const responce = await axios.post(
          domain + `/update-user/` + payload.id,
          payload,
          {
            headers: {
              Authorization: `Bearer ` + this.state.token,
            },
          }
        )

        commit('UPDATE_USERS', responce.data.data)

        commit('SET_LOADINGUSER', false)
        return true
      } catch (error) {
        commit('SET_LOADINGUSER', false)
        return false
      }
    },

    async delete_user({ commit }, payload) {
      commit('SET_LOADINGUSER', true)
      try {
        await axios.delete(domain + `/delete-user/` + payload.id, {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        })
        commit('DELETE_USER', payload)
        commit('SET_LOADINGUSER', false)
        return true
      } catch (error) {
        commit('SET_LOADINGUSER', false)
        return false
      }
    },

    async all_pdf({ commit }) {
      commit('SET_LOADINGPDF', true)
      try {
        const responce = await axios.get(domain + `/index`, {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        })

        commit('SET_PDF', responce.data.data.data)
        commit('SET_LOADINGPDF', false)
        return true
      } catch (error) {
        commit('SET_LOADINGPDF', false)
        return false
      }
    },

    async store_pdf({ commit }, payload) {
      commit('SET_LOADINGPDF', true)
      try {
        await axios.post(domain + `/storePdf`, payload, {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        })
        commit('PUSH_PDF', payload)
        commit('SET_LOADINGPDF', false)
        Router.replace('/audit')
        return true
      } catch (error) {
        commit('SET_LOADINGPDF', false)
        return true
      }
    },

    async edit_pdf({ commit }, payload) {
      commit('SET_LOADINGPDF', true)
      try {
        const responce = await axios.post(
          domain + `/update/` + payload.id,
          payload,
          {
            headers: {
              Authorization: `Bearer ` + this.state.token,
            },
          }
        )

        commit('UPDATE_PDF', responce.data.data)

        commit('SET_LOADINGPDF', false)
        return true
      } catch (error) {
        commit('SET_LOADINGPDF', false)
        return false
      }
    },

    async delete_pdf({ commit }, payload) {
      commit('SET_LOADINGPDF', true)
      try {
        await axios.delete(domain + `/delete/` + payload.id, {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        })
        commit('DELETE_PDF', payload)
        commit('SET_LOADINGPDF', false)
        return true
      } catch (error) {
        commit('SET_LOADINGPDF', false)
        return false
      }
    },

    async download_pdf({ commit }, id) {
      commit('SET_LOADINGPDF', true)
      try {
        const response = await axios.get(domain + `/downloadPDF/` + id, {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
          responseType: 'blob',
        })

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'audit.pdf')
        document.body.appendChild(link)
        link.click()

        commit('SET_LOADINGPDF', false)
        return true
      } catch (error) {
        commit('SET_LOADINGPDF', false)
        return false
      }
    },
  },
})
