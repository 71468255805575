import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
      meta: { guest: true },
  },
  {
    path: "/user",
    name: "User",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/User.vue"),
    meta: { auth: true },
  },
  {
    path: "/audit",
    name: "Audit liste",
    component: () =>
      import(/* webpackChunkName: "liste audit" */ "../views/Audit.vue"),
    meta: { auth: true },
  },
  {
    path: "/audit/new",
    name: "Audit creation",
    component: () =>
      import(/* webpackChunkName: "creation audit" */ "../views/NewPDF.vue"),
    meta: { auth: true },
  },
  {
    path: "**",
    component: () =>
      import(/* webpackChunkName: "creation audit" */ "../views/Audit.vue"),
    meta: { auth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    // this route requires auth user, check if logged in
    // if not, redirect to login page.
    if (!store.state.token) {
      next({
        path: "/",
      });
    } else {
      next();
    }
    //this route requires guest user
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (store.state.token) {
      next({
        path: "/audit",
      });
    } else {
      next();
    }
    // this route requires nothing
  } else {
    next();
  }
});


export default router;
